var animationTivel = lottie.loadAnimation({
    container: document.getElementById('tivel'),
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: 'json/data.json'
});

$(function(){
    
    // program
    $('.program-wrap__video').on('inview', function(event, isInView) {
        var video = $('#video').get(0);
        if (isInView) {
            $('.program-wrap__title').addClass("fade-title");
            video.stop().play();
        }
    });
    $('.program-wrap__box').on('inview', function(event, isInView) {
        if (isInView) {
            animationTivel.play(); 
            $(this).children('div').addClass("fade-program-box");
        }
    });
    
    $('.program-wrap__next').on('inview', function(event, isInView) {
        if (isInView) {
            $(".box-message__date").addClass("fade-next-date");
            $(".box-message__name").addClass("fade-next-name");
            $(".box-message__wrap").addClass("fade-next-wrap");
        }
    });

    // artists
    $('.artists-wrap__content').on('inview', function(event, isInView) {
        if (isInView) {
            $(".item01").addClass("active01");
            $(".item02").addClass("active02");
            $(".item03").addClass("active03");
            $(".item04").addClass("active04");
            $(".item05").addClass("active05");
            $(".item06").addClass("active01");
            $(".item07").addClass("active02");
        }
    });
    $('.artists-wrap__title').on('inview', function(event, isInView) {
        if (isInView) {
            $(this).addClass("fade-title");
        }
    });

    // specialists
    $('.specialists-wrap__title').on('inview', function(event, isInView) {
        if (isInView) {
            $(this).addClass("fade-title");
        }
    });

    $(".list-item__profile").hide();
    if (window.matchMedia('(min-width: 768px)').matches) {
        $('.specialists-wrap__list .list-item').hover(
            function() {
                $(this).children(".list-item__profile").fadeIn();
            },
            function(){
                $(this).children(".list-item__profile").fadeOut();
            }
        );
    }
    
    // buy
    $('.buy').on('inview', function(event, isInView) {
        if (isInView) {
            $(".buy-wrap").addClass("fade-buy-wrap");
        }
    });

    // gallery
    $('.gallery').on('inview', function(event, isInView) {
        if (isInView) {
            $(".gallery").addClass("fade-gallery-line");
            $(".gallery-wrap").addClass("fade-gallery-wrap");
        }
    });

    // collector
    $('.collector').on('inview', function(event, isInView) {
        if (isInView) {
            $(this).addClass("fade-collector-line");
            $(".collector-wrap").addClass("fade-collector-wrap");
        }
    });

    // curators
    $('.curators').on('inview', function(event, isInView) {
        if (isInView) {
            $(this).addClass("fade-curators-line");
            $(".curators-wrap").addClass("fade-curators-wrap");
        }
    });

    // curators
    // $('.other').on('inview', function(event, isInView) {
    //     if (isInView) {
    //         $(this).addClass("fade-other-line");
    //     }
    // });

    // news
    $('.news-wrap__title').on('inview', function(event, isInView) {
        if (isInView) {
            $(this).addClass("fade-title");
            $('.news-wrap__content').addClass("fade-news-content");
        }
    });

    // client
    $('.client').on('inview', function(event, isInView) {
        if (isInView) {
            $('.client-wrap__img').addClass("fade-client-img");
            $('.client-wrap__txt').addClass("fade-client-txt");
        }
    });

    
    if (window.matchMedia('(max-width: 767px)').matches) {
        
        const swiper = new Swiper(".swiper", {
            slidesPerView: 3,
            loop: true,
            loopedSlides: 3,
            speed: 1000,
            touchEventsTarget: false,
            autoplay: {
                delay: 3000,
                disableOnInteraction: true,
                reverseDirection: true,
            },
        });
    } else {
        const swiper = new Swiper(".swiper", {
            slidesPerView: 5,
            loop: true,
            loopedSlides: 5,
            speed: 1000,
            touchEventsTarget: false,
            autoplay: {
                delay: 3000,
                disableOnInteraction: true,
                reverseDirection: true,
            },
        });
    }

    const swiper_artist = new Swiper(".swiper_artist", {
        loop: true,
        pagination: {
            el: ".swiper-pagination__artist"
        },
        speed: 1000,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    });
    
    const swiper_collector = new Swiper(".swiper_collector", {
        loop: true,
        pagination: {
            el: ".swiper-pagination__collector"
        },
        speed: 1000,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    });
});

